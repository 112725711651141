<template>
  <div class="route">
    <div class="route__content">
        <router-link v-if="route.path.includes('vacancies')" :to="`/vacancies/${route.params.id}`" class="route__back">
            <img src="@/assets/images/icons/back-arrow.svg" alt="back">
            {{fieldsVacancies?.vacancy?.title}}
        </router-link>
        <router-link v-if="route.path.includes('template')" :to="`/templates/${route.params.id}`" class="route__back">
          <img src="@/assets/images/icons/back-arrow.svg" alt="back">
          {{fieldsVacancies?.vacancy?.title}}
        </router-link>

        <h1 class="route__title">Маршруты согласования</h1>
        <p class="route__text">
          Маршруты согласования, сформированы вами в рамках создания вакансии.
          В случае если вам нужно добавить еще маршрут, вы можете это сделать отредактировав вакансию
        </p>

        <div class="container">
        <AddParticipantsPreview
            v-if="fieldsVacancies.vacancy?.routes"
            :owner="fieldsVacancies?.vacancy_owner_employee?.full_name"
            v-model="fieldsVacancies.vacancy.routes[selectedRoute].stages"
            :key="selectedRoute"
        />
<!--            <ApprovalParticipants v-if="fieldsVacancies.vacancy" :fields="fieldsVacancies" class="route__block"/>-->
        </div>
    </div>
    <div>
      <CoordinationRoutes
          v-if="fieldsVacancies.vacancy?.routes"
          v-model="selectedRoute"
          :routes="fieldsVacancies.vacancy.routes"
          style="margin-top: 140px"
          only-switch
      />
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import AddParticipantsPreview from '@/components/CreateVacancy/AddParticipantsPreview'
import {useRouter, useRoute} from "vue-router";
import vacancies from "@/api/vacancies";
// import ApprovalParticipants from "../../components/VacancyDetailCard/ApprovalParticipants"
import CoordinationRoutes from "@/components/CreateVacancy/CoordinationRoutes/index.vue";

const route = useRoute();
const router = useRouter();

const fieldsVacancies = ref({});
const vacancyId = ref(route.params.id);
const selectedRoute = ref(0)

const getFieldsVacancies = async () => {
    try {
        // fieldsVacancies.value = await vacancies.getFieldsVacancies({
        //     vacancy_id: vacancyId.value,
        // });
        fieldsVacancies.value = await vacancies.getFieldsVacancies(vacancyId.value);

    } catch (error) {
        console.log(error);
    }
};

onMounted(async () => {
    await getFieldsVacancies();
});
</script>

<style scoped lang="scss">
.route {
  display: flex;
  gap: 32px;
    &__back {
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: $gray;
        text-decoration: none;

        img {
            width: 20px;
            margin-right: 4px;
        }
    }

    &__title {
        margin-top: 8px;
        font-size: 28px;
        font-weight: 700;
    }

    &__text {
        max-width: 780px;
        margin-top: 8px;
        color: $gray;
        font-size: 14px;
        font-weight: 400;
    }

    &__block {
        margin-top: 24px;
    }
}
</style>
